import React from "react"
import styled from "styled-components"
import Header from "../../components/profissionaisSaude/Header"
import Clipboard from "../../assets/icons/Clipboard"
import DiversityIcon from "../../assets/icons/DiversityIcon"
import BoxInfoLink from "../../components/profissionaisSaude/BoxInfoLink"
import Footer from "../../components/profissionaisSaude/Footer"
import spacing from "../../styles/tokens/spacing"
import PrivacyIcon from "../../assets/icons/PrivacyIcon"

const Container = styled.div`
  max-width: 69.375rem;
  margin: 0 auto;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  section {
    width: 100%;
    max-width: 90rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: ${({ theme }) => theme.spacing["2xl"]} auto ${spacing.layout2l};
    gap: 3.5625rem;
  }

  .screen-side {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .img-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    height: 100%;
  }

  .pro-image {
    width: 21.8125rem;
    height: 22.0625rem;
  }

  .image-credits {
    color: ${({ theme }) => theme.colors.gray.dark};
    font-size: ${({ theme }) => theme.fontSize.fontSize.fontSizeXS};
    white-space: nowrap;

    .anchor-image {
      cursor: pointer;
      color: ${({ theme }) => theme.colors.gray.dark};
      text-decoration: underline;
    }
  }

  @media screen and (max-width: 1020px) {
    padding: 0;
    gap: 0;
    justify-content: center;

    section {
      padding: 0 ${({ theme }) => theme.spacing.xl};
    }

    .img-wrapper {
      @media screen and (max-width: 700px) {
        display: none;
      }
    }

    .pro-image {
      max-width: 40vw;
      max-height: 36vw;
    }

    form {
      width: 80vw;
    }
  }
`
const BoxIntroText = styled.div`
  color: ${({ theme }) => theme.colors.gray.text_base};
  display: flex;
  flex-direction: column;

  h1 {
    font-size: ${({ theme }) => theme.fontSize.fontSize.fontSize4xL};
    font-weight: ${({ theme }) => theme.fontWeight.fontWeight.fontWeightBold};
    display: inline-block;
    margin-bottom: ${spacing["2l"]};
    max-width: 37.75rem;
  }

  p {
    font-size: ${({ theme }) => theme.fontSize.fontSize.fontSizeBase};
    font-weight: ${({ theme }) => theme.fontWeight.fontWeight.fontWeightNormal};
    display: inline-block;
    max-width: 33.6875rem;
    margin-bottom: ${({ theme }) => theme.spacing["2xl"]};
  }
`

const BoxHolder = styled.div`
  gap: 1.875rem;
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 1200px) {
    display: flex;
    flex-direction: row;
  }
`

export default function ProfissionaisSaude() {
  return (
    <Container>
      <Header isLogged />
      <section>
        <div item className="screen-side">
          <BoxIntroText>
            <h1>Boas-vindas à Lacrei Saúde!</h1>
            <p>
              Você faz a diferença para milhares de pessoas LGBTQIAPN+ que
              buscam equidade, respeito e segurança nos serviços de saúde.
              Complete seu perfil para divulgá-lo na plataforma Lacrei Saúde.
            </p>
          </BoxIntroText>
          <BoxHolder>
            <BoxInfoLink
              text="Complete e edite seu perfil com dados profissionais"
              buttonText="Editar Cadastro"
              link="/saude/cadastro-dados-pessoais/"
              icon={<Clipboard />}
            />

            <BoxInfoLink
              text="Complete seu perfil com dados de diversidade"
              buttonText="Diversidade"
              link="/saude/cadastro-diversidade/"
              icon={<DiversityIcon />}
            />

            <BoxInfoLink
              text="Acesse a área de Segurança e Privacidade"
              buttonText="Segurança e Privacidade"
              link="/seguranca/minha-conta/"
              icon={<PrivacyIcon width="38" height="37" />}
            />
          </BoxHolder>
        </div>
      </section>
      <Footer />
    </Container>
  )
}
